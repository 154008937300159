


import Locator from 'kiva/components/blocks/locator/locator'
export default

	props: block: Object

	# Render the locator
	render: (create) -> create Locator, props:
		block: @block
		noResultsList: true
		forcePinIcon: true
		forceOverviewMode: true
		showAll: true

