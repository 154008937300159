# Settings
SPEED_MODIFIER = 0.75 # Dampen parallax effect globally

# Adds classes to wrapped components that inform the smooth scroller.
export default
	name: 'ScrollResponder'
	functional: true

	inject: blockIndex: default: -> 0

	props:

		# Disable scroll responder, used for conditionally adding
		disable: Boolean

		# Part of a css class name
		reveal: String

		# See:
		# https://github.com/locomotivemtl/locomotive-scroll#element-attributes
		speed: String | Number
		delay:
			type: String | Number
			default: 0.1 # Continue to slide a bit after scrolling
		offset: String | Number
		horizontal: Boolean
		repeat: Boolean

		# Add a delay to the viewport transition
		stagger: String | Number

	render: (create, {
		props: { reveal, speed, delay, offset, horizontal, stagger, repeat,
			disable },
		injections: { blockIndex },
		children
	}) ->
		return children if disable
		return children.map (vm) ->

			# Make containers
			vm.data = {} unless vm.data
			vm.data.attrs = {} unless vm.data.attrs
			vm.data.class = switch
				when vm.data.class == undefined then []
				when Array.isArray vm.data.class then vm.data.class
				else [vm.data.class]
			vm.data.style = {} unless vm.data.style

			# Default to 10% if revealing
			offset = '10%' if reveal and offset == undefined

			# Register scroll-ness and optional data attributes
			vm.data.attrs['data-scroll'] = true
			vm.data.attrs['data-scroll-speed'] = speed * SPEED_MODIFIER if speed
			vm.data.attrs['data-scroll-delay'] = delay if delay
			vm.data.attrs['data-scroll-offset'] = offset if offset
			vm.data.attrs['data-scroll-position'] = 'top' if blockIndex == 0
			vm.data.attrs['data-scroll-direction'] = 'horizontal' if horizontal
			vm.data.attrs['data-scroll-repeat'] = 'true' if repeat

			# Add reveal class
			vm.data.class.push "#{reveal}-reveal" if reveal

			# Add stagger
			vm.data.style['--stagger'] = "#{stagger}s" if stagger
			vm.data.class.push 'stagger-reveal' if stagger

			return vm
