













export default

	props: 
		links: Array
		buttons: Array # When used by a Kiva component, like the copy block

	computed:

		# Get the list of links, removing empties
		goodLinks: -> 
			(@links || @buttons || [])
			.filter ({ href }) -> !!href


