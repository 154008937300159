
















export default

	props: height: Number

	data: -> forceBackground: false

	mounted: -> @forceBackground = Modernizr.ie

	computed:

		# Is the page currently loading
		loading: -> @$store.state.loading

		# If using smooth scrolling, make this feel like it scrolls off screen
		# naturally
		transformOffset: ->
			if @$scroll.up or @loading then 0
			else -1 * Math.min @height, @$scroll.y

		# Toggle the transformation duration time so there is no animation when the
		# offset is lockstep with the scroll
		tweenOffset: -> @$scroll.up or @$scroll.y > @height

		# Always show the background color when down the page
		showBackground: -> @loading or @$scroll.y > @height or @forceBackground

		# Build CSS
		styles: -> {
			...@$store.getters["promo-bars/offsetStyles"]
			transform: "translateY(#{@transformOffset}px)"
		}
		classes: ->
			'tween-transfrom': @tweenOffset
			'show-bkgd': @showBackground

