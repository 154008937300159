


import CardGrid from 'kiva/components/blocks/card-grid'
export default

	props: block: Object

	render: (create) -> create CardGrid, props: { @block }

