
































export default

	props: block: Object

	data: -> selectedId: @block.slides?[0]?.id

	computed:

		# Get the active slide
		activeSlide: -> @block.slides.find ({ id }) => id == @selectedId

	methods:

		# Update slide's inview
		onChange: -> @$defer => @$scrollbar.update()

