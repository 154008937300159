import kebab from 'lodash/kebabCase'

export state = ->

	# Make the nav ... this could be fetched from the CMS in the future
	header: [
		'About'
		'Brands'
		'Coverage'
		'Services'
		'Partners'
		'Contact'
	].map (label) ->
		title: label
		href: "/##{kebab(label)}"

	# The support email address
	support: 'info@kivaconfections.com'
