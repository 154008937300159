
















import DesktopHeader from '~/components/layout/desktop-header'
import MobileHeader from '~/components/layout/mobile-header/mobile-header'
import GlobalFooter from '~/components/layout/global-footer'
export default

	components: {
		DesktopHeader
		MobileHeader
		GlobalFooter
	}

