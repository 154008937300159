


import Copy from 'kiva/components/blocks/copy'
export default

	props: block: Object

	render: (create) -> create Copy, props: { @block }

