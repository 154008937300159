
























import AffixingHeader from './affixing-header'
import vars from '~/assets/vars/layout.json'
export default

	components:  { AffixingHeader }

	data: -> headerH: parseInt vars['header-h']

