# Deps
import Vue from 'vue'
import LocomotiveScroll from 'locomotive-scroll'
import 'locomotive-scroll/dist/locomotive-scroll.css'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import throttle from 'lodash/throttle'
import { defer } from 'kiva/plugins/helpers'

# Make the scrollY position a global obverserable.  I tried using VueX
# but it super bogged down the dev tools ... too many mutations.
# https://mobile.twitter.com/JakeDohm/status/1111285005639213056/photo/1
export scroll = Vue.observable
	y: 0
	up: false

# Make scrollbar instance with public methods for scrolling
class Scrollbar

	# Dependency inject
	constructor: ({ @store }) ->

	# Init smooth scrolling
	init: ->
		return if Modernizr.ie # No IE
		return if @scroller
		return unless @isScrollable()

		# Create scroller instance
		@scroller = new LocomotiveScroll
			el: @getContainer()
			smooth: !process.env.SMOOTH_SCROLL_DISABLE

		# Track the scroll position
		@scroller.on 'scroll', (data) ->
			scroll.up = data.scroll.y - scroll.y < 0
			scroll.y = data.scroll.y

		# Handle in-viewport calls
		@scroller.on 'call', @onInView.bind @

		# Add a class that is used for reveal animations. Not using the classes
		# that are added by LocomotiveScroll since they aren't added when then
		# page is too short.
		defer -> document.documentElement.classList.add 'scroller-inited'

	# Test if scroller active
	isEnabled: -> !!@scroller

	# Test if the content is too short for scrolling
	isScrollable: ->
		return false unless container = @getContainer()
		return @container.clientHeight > window.innerHeight

	# Lookup and cache the container for smooth scrolling
	getContainer: ->
		return @container if @container
		@container = document.getElementById 'smooth-scroll'

	# On the "call" event of the scroller, delegate a handling function. Currently
	onInView: (dataValue, event, { el }) ->
		{ type, payload } = JSON.parse dataValue
		switch type
			when 'block' then @onBlockInView payload if event == 'enter'
			when 'visual' then @onVisualInView el if event == 'enter'
			else throw "Unknown in view call type: #{type}"

	# Set the background color when a block enters the viewport
	onBlockInView: ({ background }) ->
		@store.commit 'layout/setBackground', background

	# Add in-view as a data attribute so it doesn't get destroyed by visual's
	# dynamic classes
	onVisualInView: (el) -> el.dataset.isInview = true

	# Passthrough scrollTo method
	scrollTo: (target, offset = null) ->

		# Automatically apply the header offset
		if offset == null
			offset = if window.innerWidth >= 768 then -80 else -50

		# Do the scroll
		if @scroller? then @scroller.scrollTo target, offset
		else
			if target == 'top' then window.scrollTo 0, 0
			else target.scrollIntoView?()

	# Destroy the scroller instance and cleanup
	destroy: ->
		return unless @scroller

		# Destroy scroller instance
		@scroller.destroy()
		@scroller = null

		# Clear the old style position
		@getContainer().style = null

		# Replicate the previous scroll position
		window.scrollTo 0, scroll.y

		# Remove init class
		document.documentElement.classList.remove 'scroller-inited'

	# Tell smooth scroll to recalculate after a layout change.  Note, this does
	# detect new elements as well.
	update: throttle ->
		@scroller?.update()
	, 100

	# Disable scrolling, like when mobile nav is open
	disable: (el) ->
		disableBodyScroll el
		@scroller?.stop()
		document.querySelector('.c-scrollbar')?.classList.add 'hide'

	# Re-enable body scroll
	enable: (el) ->
		enableBodyScroll el
		@scroller?.start()
		document.querySelector('.c-scrollbar')?.classList.remove 'hide'

# Inject globals
export default ({ app, store }, inject) ->

	# Make scrollbar instance
	scrollbar = new Scrollbar({ store })
	scrollbar.init() if scrollbar.isScrollable()

	# On any HMR change, re-init scroll, so I can quickly test parallax changing
	unless process.env.SMOOTH_SCROLL_DISABLE
		module.hot?.addStatusHandler (status) ->
			return unless status == 'idle'
			Vue.nextTick -> scrollbar.update()

	# Add smooth scroll if the window is resized and it isn't currently enabled,
	# perhaps because it was too short at the time
	window?.addEventListener 'resize', throttle ->
		if scrollbar.isEnabled()
		then scrollbar.destroy() unless scrollbar.isScrollable()
		else scrollbar.init() if scrollbar.isScrollable()
	, 500

	# When the route change, scroll to top ... the main purpose of this is to
	# fire a scroll event so that header shows itself if it was hidden when the
	# route change was initiated.
	app.router.afterEach (to, from) ->
		scrollbar.scrollTo 'top' if to.path != from.path

	# Inject instances
	inject 'scroll', scroll
	inject 'scrollbar', scrollbar
