























export default

	props: block: Object

	data: ->
		hideImgs: false

	# Instantiate and cleanup Flickity
	mounted: -> @flickity = @initFlickity()
	destroyed: -> @flickity?.destroy()

	computed:

		# Add classes
		classes: -> 'hide-imgs': @hideImgs

	methods:

		# Create flickity
		initFlickity: -> new @$flickity @$refs.carousel,
			wrapAround: true
			freeScroll: true
			pageDots: false
			prevNextButtons: false

			# Trigger intesection-observer based loading of images
			on:
				ready: @onFlickityReady

		# Toggle the display none of internal visuals to trigger a new intersection
		# observer check, because the load detection was failing when Flickity
		# mounts.
		onFlickityReady: ->
			@hideImgs = true
			@$defer => @hideImgs = false

