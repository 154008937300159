# Variables related to the general layout of the page
export state = ->

	# Header dimensions
	header: 
		gutter: null

	# Viewport size
	viewport:
		width: null
		height: null

export mutations =

	setHeaderGutter: (state, width) -> state.header.gutter = width

	setViewportDimensions: (state, dimensions) -> state.viewport = dimensions
