# Deps
import {
	defer, wait, metaTag, isDark, kebab, debounce, padNum, nonEmpty, validators,
	loadScript,
} from 'kiva/plugins/helpers';
import _kebabCase from 'lodash/kebabCase'

# Inject utils
export default ({ error }, inject) ->

	# Make 404 response
	inject 'notFound', -> error
		statusCode: 404
		message: 'Page not found'

	# Inject exported functions
	inject 'defer', defer
	inject 'wait', wait
	inject 'metaTag', metaTag
	inject 'isDark', isDark
	inject 'kebab', _kebabCase
	inject 'debounce', debounce
	inject 'padNum', padNum
	inject 'nonEmpty', nonEmpty
	inject 'validators', validators
	inject 'loadScript', loadScript
