





































# Make a % value
perc = (val) -> "#{val || 0}%"

# Component def
export default

	inject: ['blockIsDark']

	props: block: Object

	computed:

		# Position the label
		labelStyles: -> marginLeft: perc @block.labelLeftEdge

		# Position the copy
		copyStyles: ->
			paddingLeft: perc @block.copyLeftEdge
			paddingRight: perc @block.copyRightEdge

		# Which direction to slide to
		slideDirection: -> if @block.labelLeftEdge > 5 then 'left' else 'right'


