














export default

	props: 

		# The main button type
		type:
			type: String
			default: 'filled'
			validator: (val) -> val in ['text', 'filled']

		# Button color
		color: 
			type: String
			default: 'green'
			validator: (val) -> !val || val in ['green', 'white', 'black']
		
		# Boolean states
		disabled: Boolean
		disableOnHover: Boolean
		loading: Boolean
		
	computed:

		classes: -> [
			"type-#{@type}"
			"color-#{@color}"
			{ 'disable-on-hover': @disableOnHover }
			{ @disabled, @loading }
		]

