import { render, staticRenderFns } from "./btn.vue?vue&type=template&id=21427e72&scoped=true&lang=pug&"
import script from "./btn.vue?vue&type=script&lang=coffee&"
export * from "./btn.vue?vue&type=script&lang=coffee&"
import style0 from "./btn.vue?vue&type=style&index=0&id=21427e72&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21427e72",
  null
  
)

export default component.exports