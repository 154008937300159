



















import BlockWrapper from 'kiva/components/global/blocks/block-wrapper'

# Block components
import AccordionCarousel from '~/components/blocks/accordion-carousel'
import Anchor from 'kiva/components/blocks/anchor'
import CardGrid from '~/components/blocks/card-grid'
import ContactForm from '~/components/blocks/contact-form'
import Copy from '~/components/blocks/copy'
import CTA from '~/components/blocks/cta'
import FeaturedCopy from '~/components/blocks/featured-copy'
import GalleryCarousel from '~/components/blocks/gallery-carousel'
import GalleryMarquee from '~/components/blocks/gallery-marquee'
import Locator from '~/components/blocks/locator'
import LogoGarden from '~/components/blocks/logo-garden'
import Marquee from '~/components/blocks/marquee'
import Spacer from '~/components/blocks/spacer'

# Mapping of Craft block matrix _typename to component
export mapping =
	blocks_accordionCarousel_BlockType: AccordionCarousel
	blocks_anchor_BlockType: Anchor
	blocks_cardGrid_BlockType: CardGrid
	blocks_contactForm_BlockType: ContactForm
	blocks_copy_BlockType: Copy
	blocks_cta_BlockType: CTA
	blocks_featuredCopy_BlockType: FeaturedCopy
	blocks_galleryCarousel_BlockType: GalleryCarousel
	blocks_galleryMarquee_BlockType: GalleryMarquee
	blocks_locator_BlockType: Locator
	blocks_logoGarden_BlockType: LogoGarden
	blocks_marquee_BlockType: Marquee
	blocks_spacer_BlockType: Spacer

# Component def for this block
export default

	components: { ...mapping, BlockWrapper }

	props: blocks: Array

	computed:

		# Filter the blocks to those that have been defined.  To fix errors in dev
		# environments after content model is created but commits with the new
		# block component have not been pulled down.
		createdBlocks: ->
			typenames = Object.keys(mapping)
			(@blocks || []).filter (block) -> typenames.includes block.__typename

# Helper for loading async data from blocks
export loadBlockData = (context, blocks = [], extra) ->
	Promise.all blocks.map (block) ->
		if func = mapping[block.__typename]?.asyncData
		then func(context, block, extra)
		else Promise.resolve()

