



















import AffixingHeader from '../affixing-header'
import Hamburger from 'kiva/components/layout/mobile-header/hamburger'
import MobileMenu from './mobile-menu'
import vars from '~/assets/vars/layout.json'
export default

	components:  {
		AffixingHeader
		Hamburger
		MobileMenu
	}

	data: ->
		headerH: parseInt vars['mobile-header-h']
		open: false

	watch:

		# When the route changes, close menu if open
		$route: -> @open = false if @open

