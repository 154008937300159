















export default

	props: block: Object

	computed: 

		classes: -> [
			"align-#{@block.alignment}"
		]

