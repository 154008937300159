


import Marquee from 'kiva/components/blocks/marquee'
export default

	props: block: Object

	render: (create) -> create Marquee, props: { @block }

