








































































































export subjects = [
	'Need brand support and product education'
	'Need displays and merchandising support'
	'New distribution inquiry'
	'Billing support / Help with my invoice'
	'Help with my order / delivery'
	'Help / change my order'
]
export default

	props: 
		block: Object
		formName: String

	data: -> 
		valid: false
		validation:
			name: [ @$validators.required ]
			email: [ @$validators.required, @$validators.email ]
			company: [ @$validators.required ]
			license: []
			subject: [ @$validators.required ]
			message: [ @$validators.required ]
		form: 
			name: ''
			email: ''
			company: ''
			license: ''
			subject: ''
			message: ''
		honeypot: ''
		subjects: subjects
		submitting: false
		submitted: false
	
	computed:

		# Should fields be readonly
		readonly: -> @submitting or @submitted

		# Make the form payload, which includes the form name for Netlify, which
		# is based on the subject so submissions can be routed to different places.
		formPayload: -> {
			...@form
			'form-name': @form.subject
		}

	methods:

		# Submit to service
		submit: -> 
			return if @submitting or @submitted or @honeypot
			return unless @$refs.form.validate()
			@submitting = true
			try await @postToEndpoint()
			catch error
				alert 'An error was encountered while processing your submission.'
				@$sentry?.captureException error
				return @submitting = false
			@submitting = false
			@submitted = true
		
		# Do the actual post
		postToEndpoint: -> @$axios
			method: 'post'
			url: @block.endpoint
			data: new URLSearchParams(@formPayload).toString()
			headers: 'content-type': 'application/x-www-form-urlencoded'

