


























export default

	computed: email: -> @$store.state.navigation.support

