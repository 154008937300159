# Deps
import Vue from 'vue'

# JS with side-effects to apply globally
import 'focus-visible'

###
# Global components
###

import Btn from '~/components/global/btn/btn'
Vue.component 'btn', Btn

import Buttons from '~/components/global/btn/buttons'
Vue.component 'buttons', Buttons

import ScrollResponder from '~/components/global/scroll-responder'
Vue.component 'scroll-responder', ScrollResponder

import Visual from 'kiva/components/global/visual/craft-visual'
Vue.component 'visual', Visual

import ResponsiveVisual from 'kiva/components/global/visual/responsive-visual'
Vue.component 'responsive-visual', ResponsiveVisual

import SmartLink from 'vue-routing-anchor-parser/smart-link'
Vue.component 'smart-link', SmartLink

# These are registered globally so it can be used in functional single file
# components like card-grid, copy

import CraftCard from 'kiva/components/global/cards/craft-card'
import ListingCard from 'kiva/components/global/cards/listing-card'
Vue.component 'craft-card', CraftCard
Vue.component 'listing-card', ListingCard
