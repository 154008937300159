

























export default

	props: block: Object

	methods:

		# Make the parallax speed of the gallery
		galleryParallax: (index) -> switch index
			when 0 then 0.5
			when 1 then -0.6
			when 2 then 1

