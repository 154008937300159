



















export default

	mounted: -> @$scrollbar.disable @scrollable = @$refs.scrollable
	beforeDestroy: -> @$scrollbar.enable @scrollable

